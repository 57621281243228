<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="8" lg="8" xl="8">
        <v-tabs v-model="selectedTab" class="flex-grow-1">
          <v-tab>{{ $t('affiliatePage.partners') }}</v-tab>
          <v-tab>{{ $t('affiliatePage.sentRequests') }}</v-tab>
          <v-tab>{{ $t('affiliatePage.receivedRequests') }}</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="4" class="d-flex justify-md-end align-center" >
        <strong v-if="affiliateId">
          {{ $t('affiliatePage.affiliateId') + ': ' + affiliateId }}
        </strong>
      </v-col>

      <v-col cols="12" md="12" lg="12" xl="12">
        <v-tabs-items v-model="selectedTab">
          <v-tab-item class="pa-1">
            <affiliate-partners
              :partners="partners"
              @openEditDialog="openEditDialog"
              @openDeleteDialog="openDeleteDialog" />
          </v-tab-item>
          <v-tab-item class="pa-1">
            <affiliate-sent-requests
              :sentRequests="sentRequests"
              @openEditDialog="openEditDialog"
              @openDeleteDialog="openDeleteDialog" />
          </v-tab-item>
          <v-tab-item class="pa-1">
            <affiliate-received-requests
              :receivedRequests="receivedRequests"
              @openDeleteDialog="openDeleteDialog"
              @openStatusDialog="openStatusDialog" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogEdit" max-width="700px">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ $t(formTitle) }}
          <v-spacer></v-spacer>
          <v-icon class="mdi mdi-close" style="color: white" @click="closeEditDialog"></v-icon>
        </v-card-title>

        <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
          <v-form lazy-validation @submit.prevent="handleSubmit(saveAffiliate)">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <validation-provider rules="required|max:200" v-slot="{ errors }" name="affiliate_id">
                      <v-text-field
                        v-model="editedItem.affiliate_id"
                        autofocus
                        clearable
                        counter="200"
                        :label="$t('affiliatePage.affiliateId')"
                        :error-messages="errors"></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <button-submit :failed="failed" :loading="loading" buttonText="buttons.send"></button-submit>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogStatus" max-width="650">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ editedItem?.affiliated_organisation?.name }}
          <v-spacer></v-spacer>
          <v-icon class="mdi mdi-close" style="color: white" @click="closeStatusDialog"></v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <p>
              {{
                editedItem?.status == 'accepted' ? $t('affiliatePage.acceptRequest') : $t('affiliatePage.rejectRequest')
              }}
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" text @click="changeStatus">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="650">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ editedItem?.affiliated_organisation?.name }}
          <v-spacer></v-spacer>
          <v-icon class="mdi mdi-close" style="color: white" @click="closeDeleteDialog"></v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <p>
              {{
                editedItem?.type == 'partner' ? $t('affiliatePage.deletePartner') : $t('affiliatePage.deleteRequest')
              }}
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" text @click="deleteItem">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import AffiliatePartners from '@/components/affiliate/AffiliatePartners.vue';
import AffiliateSentRequests from '@/components/affiliate/AffiliateSentRequests.vue';
import AffiliateReceivedRequests from '@/components/affiliate/AffiliateReceivedRequests.vue';
import { formatDateTime } from '@/utils/formatDate';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { defaultAffiliate } from '@/mixins/default-items';

export default {
  name: 'AffiliatePage',
  props: [],
  components: { AffiliatePartners, AffiliateSentRequests, AffiliateReceivedRequests, ButtonSubmit },
  data: () => ({
    partners: [],
    sentRequests: [],
    receivedRequests: [],
    selectedTab: 0,
    editedItem: {},
    dialogEdit: false,
    dialogDelete: false,
    dialogStatus: false,
    formTitle: 'affiliatePage.newItem',
    loading: false,
    affiliateId: '',
  }),
  created() {
    this.editedCurrencyItem = Object.assign({}, defaultAffiliate);
    this.loadAffiliateData();
    this.loadOrganisation();
  },
  computed: {},
  methods: {
    loadAffiliateData() {
      this.loadAffiliatePartners();
      this.loadAffiliateSentRequests();
      this.loadAffiliateReceivedRequests();
    },
    async loadAffiliatePartners() {
      await this.$store.dispatch('affiliate/getAffiliatePartners').then((res) => {
        this.partners = res.data.map((item) => {
          item.created_at = formatDateTime(item.created_at);

          return item;
        });
      });
    },
    async loadAffiliateSentRequests() {
      await this.$store.dispatch('affiliate/getAffiliateSentRequests').then((res) => {
        this.sentRequests = res.data.map((item) => {
          item.created_at = formatDateTime(item.created_at);

          return item;
        });
      });
    },
    async loadAffiliateReceivedRequests() {
      await this.$store.dispatch('affiliate/getAffiliateReceivedRequests').then((res) => {
        this.receivedRequests = res.data.map((item) => {
          item.created_at = formatDateTime(item.created_at);

          return item;
        });
      });
    },
    async loadOrganisation() {
      await this.$store.dispatch('organisations/getAllOrganisations').then((res) => {
        this.affiliateId = res.data[0].affiliate_id;
      });
    },
    openEditDialog() {
      this.editedItem = Object.assign({}, defaultAffiliate);
      this.dialogEdit = true;
    },
    closeEditDialog() {
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultAffiliate);
      });
      this.$refs.form.reset();
    },
    async saveAffiliate() {
      this.enableSave = false;
      this.loading = true;

      await this.$store
        .dispatch('affiliate/saveAffiliate', this.editedItem)
        .then(() => {
          this.loadAffiliateData();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.sentSuccessfully'), color: 'green' });
          this.closeEditDialog();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enableSave = true;
          this.loading = false;
        });
    },
    openStatusDialog(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogStatus = true;
    },
    closeStatusDialog() {
      this.dialogStatus = false;
      this.editedItem = Object.assign({}, defaultAffiliate);
    },
    async changeStatus() {
      await this.$store
        .dispatch('affiliate/changeAffiliateRequestStatus', { id: this.editedItem.id, status: this.editedItem.status })
        .then(() => {
          this.$store.dispatch('showSnackbar', {
            text: i18n.t('snackbar.statusChanged'),
            color: 'green',
          });
          this.closeStatusDialog();
          this.loadAffiliateData();
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.closeStatusDialog();
        });
    },
    openDeleteDialog(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
      this.editedItem = Object.assign({}, defaultAffiliate);
    },
    async deleteItem() {
      await this.$store
        .dispatch('affiliate/deleteAffiliate', this.editedItem)
        .then((res) => {
          if (res.success) {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
            this.closeDeleteDialog();
            this.loadAffiliateData();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.closeDeleteDialog();
        });
    },
  },
  watch: {
    dialogEdit(val) {
      val || this.closeEditDialog();
    },
  },
};
</script>
